import {ACCOUNTANT_ROLE} from './constants/accountant-constants';
import {HTTP_403_FORBIDDEN} from '@jetCommon/constants/http-status';
import {START_LOCATION} from 'vue-router';
import {goToSSO} from '@jetCommon/helpers/navigation';
import {storeToRefs} from 'pinia';
import {useAppPlatform} from '@jetCommon/composables/app-platform';
import {useAuthStore} from '@jetCommon/stores/auth';
import {useJetBuildVersionStore} from '@jetCommon/stores/jet-build-version';
import {usePushNotificationsStore} from '@jetCommon/stores/push-notifications';
import settings from '@/env';

const commonRoutes = [
    {
        path: '/accesso-negato',
        component: () => import('@jetCommon/views/PermissionDeniedView.vue'),
        meta: {requiresAuth: false},
        name: 'permissionDeniedView',
    },
    {
        path: '/404',
        component: () => import('@jetCommon/views/NotFoundView.vue'),
        meta: {requiresAuth: false},
        name: 'notFoundView',
    },
];

function isSpaSupportedInApp(spa) {
    return spa === 'employee';
}

function redirectToNotSupported() {
    window.location = settings.NOT_SUPPORTED_IN_APP_URL;
}

async function beforeEachHandler(to, from, api, useSpaStore, jetRouteEntityName = null) {
    const authStore = useAuthStore();
    authStore.init(api);

    const pushNotificationsStore = usePushNotificationsStore();
    pushNotificationsStore.init(api);

    const spaStore = useSpaStore ? useSpaStore() : null;

    const isMobileApp = useAppPlatform().isMobileApp();

    // if not specified, auth is always required
    const requiresAuth = !('requiresAuth' in to.meta) || ('requiresAuth' in to.meta && to.meta.requiresAuth);
    // if not specified, jet_role is not required
    const requiresJetRole = 'requiresJetRole' in to.meta && to.meta.requiresJetRole;
    const requiresStandardAccountantRole =
        'requiresStandardAccountantRole' in to.meta && to.meta.requiresStandardAccountantRole;

    if (requiresAuth) {
        const isJetRouteEntityValueChanged = jetRouteEntityName
            ? from.params[jetRouteEntityName] !== to.params[jetRouteEntityName]
            : false;
        if (from === START_LOCATION || isJetRouteEntityValueChanged) {
            try {
                const {spa, capabilities} = (
                    await api.persons.noMsgForStatusCodes([HTTP_403_FORBIDDEN]).checkSpaAccess(window.location.href)
                ).data;
                if (isMobileApp && !isSpaSupportedInApp(spa)) {
                    redirectToNotSupported();
                    return false;
                }
                authStore.setCapabilities(capabilities);
                await authStore.fetchPerson();
                if (spaStore) {
                    await spaStore.onJetRouteEntityValueChange(to.params[jetRouteEntityName]);
                }
            } catch (error) {
                goToSSO();
                return false;
            }
        }

        if (!authStore.isPersonAuthenticated) {
            goToSSO();
            return false;
        }
    }
    if (requiresJetRole) {
        try {
            const {spa} = (
                await api.persons.noMsgForStatusCodes([HTTP_403_FORBIDDEN]).checkSpaAccess(window.location.href)
            ).data;
            if (isMobileApp && !isSpaSupportedInApp(spa)) {
                redirectToNotSupported();
                return false;
            }
            if (['admin', 'employee'].includes(spa) && !authStore.hasJetRole) {
                goToSSO();
                return false;
            }
        } catch (error) {
            goToSSO();
            return false;
        }
    }
    if (requiresStandardAccountantRole) {
        try {
            if (spaStore) {
                const {accountantRole} = spaStore;
                if (accountantRole.role !== ACCOUNTANT_ROLE.STANDARD) {
                    return false;
                }
            }
        } catch (error) {
            goToSSO();
            return false;
        }
    }
}

function afterEachHandler(to, from) {
    // Hard reload current URL if a new deployment has been completed
    const {buildVersion} = storeToRefs(useJetBuildVersionStore());
    if (settings.ENV === 'local_dev' && settings.JET_BUILD_VERSION === '') {
        settings.JET_BUILD_VERSION = buildVersion.value;
    }
    if (
        to.fullPath !== from.fullPath &&
        buildVersion.value !== '' &&
        settings.JET_BUILD_VERSION !== '' &&
        buildVersion.value > settings.JET_BUILD_VERSION
    ) {
        window.location.reload();
    }
}

function onError(error, to) {
    if (error?.message.includes('Failed to fetch dynamically imported module')) {
        window.location = to.fullPath;
    }
}

// eslint-disable-next-line no-unused-vars
function scrollBehavior(to, from, savedPosition) {
    const mainElement = document.querySelector('main');
    if (mainElement) {
        mainElement.scrollTop = 0;
    }
}

export {afterEachHandler, beforeEachHandler, onError, commonRoutes, scrollBehavior};
