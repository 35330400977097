import {ElMessage} from 'element-plus';
import {h} from 'vue';
import {useSafeAreaInset} from '@jetCommon/composables/safe-area-inset';

import JetIcon from '@jetDS/components/JetIcon.vue';
import JetMessageContent from '@jetDS/components/JetMessageContent.vue';

export function useJetMessage() {
    const safeAreaInset = useSafeAreaInset();

    const baseOptions = {showClose: true, duration: 5000, offset: 16 + safeAreaInset.top};

    function renderJetMessageContent(message, actionLabel) {
        return h(JetMessageContent, {message, actionLabel});
    }

    const defaultIconComponent = h(JetIcon, {
        name: 'circle-info',
        iconStyle: 'regular',
    });
    const infoIconComponent = h(JetIcon, {
        name: 'circle-info',
        iconStyle: 'regular',
    });
    const successIconComponent = h(JetIcon, {
        name: 'circle-check',
        iconStyle: 'regular',
    });
    const warningIconComponent = h(JetIcon, {
        name: 'triangle-exclamation',
        iconStyle: 'regular',
    });
    const errorIconComponent = h(JetIcon, {
        name: 'circle-exclamation',
        iconStyle: 'regular',
    });

    function _showMessage(message, options, icon, cssClassNameKind) {
        // ElMessage returns instance of rendered message.
        // We can invoke .close() on it to close message
        return ElMessage({
            ...baseOptions,
            ...{
                message: renderJetMessageContent(message, options.actionLabel),
                customClass: `jet-message-${cssClassNameKind}--${options.style}`,
                icon,
            },
            ...options.extraOptions,
        });
    }

    return {
        default(message, options) {
            return _showMessage(message, {style: 'plain', ...options}, defaultIconComponent, 'default');
        },
        info(message, options) {
            return _showMessage(message, {style: 'plain', ...options}, infoIconComponent, 'info');
        },
        success(message, options) {
            return _showMessage(message, {style: 'plain', ...options}, successIconComponent, 'success');
        },
        warning(message, options) {
            return _showMessage(message, {style: 'plain', ...options}, warningIconComponent, 'warning');
        },
        error(message, options) {
            return _showMessage(message, {style: 'solid', ...options}, errorIconComponent, 'error');
        },
        closeAll() {
            ElMessage.closeAll();
        },
    };
}
