import {api} from '@/api';
import {defineStore, storeToRefs} from 'pinia';
import {useAuthStore} from '@jetCommon/stores/auth';
import {useJetMessage} from '@jetCommon/composables/jet-message';

const jetMessage = useJetMessage();

export const useSpaStore = defineStore('spa', () => {
    const authStore = useAuthStore();
    const {isPersonAuthenticated, person} = storeToRefs(authStore);
    const accountingOffice = ref(null);
    const accountingOfficeId = ref(null);

    async function fetchAccountingOffice() {
        accountingOffice.value =
            (await api.accountingOffices.retrieve(accountingOfficeId.value).catch(api.end))?.data ?? null;
    }

    const patchAccountingOfficeField = field => value => {
        const data = {
            [field]: value,
        };
        return api.accountingOffices.patch(accountingOfficeId.value, data).then(() => {
            jetMessage.success('Le modifiche sono state salvate correttamente');
            accountingOffice.value[field] = value;
        });
    };

    async function onJetRouteEntityValueChange(jetRouteEntityValue) {
        accountingOfficeId.value = Number(jetRouteEntityValue);
        await fetchAccountingOffice();
    }

    const accountantRole = computed(() => {
        if (!accountingOfficeId.value) {
            return null;
        }
        return isPersonAuthenticated.value && person.value.accountant_role;
    });

    watch(accountingOfficeId, async () => {
        await fetchAccountingOffice();
    });

    const accountantId = computed(() => accountantRole.value?.accountant_id ?? null);

    return {
        onJetRouteEntityValueChange,
        accountingOffice,
        accountingOfficeId,
        accountantRole,
        patchAccountingOfficeField,
        accountantId,
    };
});
