import {ApiBase} from '@jetCommon/api/base';

export default class CompanyDocumentApiCommon extends ApiBase {
    static resourceName = 'company_documents';

    treeView(companyId, params, config) {
        return this.apiGet(`tree_view/${companyId}/`, {...config, params});
    }

    getByCompanyPayroll(companyId, payrollId, config) {
        return this.apiGet(`get_by_company_payroll/${companyId}/${payrollId}/`, config);
    }
}
