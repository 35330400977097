import {ApiBase} from '@jetCommon/api/base';

export default class AccountingOfficeApiCommon extends ApiBase {
    static resourceName = 'accounting_offices';

    getCompanyChoices(accountingOfficeId) {
        return this.apiGet(`${accountingOfficeId}/company_choices/`);
    }

    addAccountant(accountingOfficeId, data) {
        return this.apiPost(`${accountingOfficeId}/add_accountant/`, data);
    }

    getIntercomConfiguration(officeId) {
        return this.apiGet(`${officeId}/intercom_configuration/`);
    }
}
