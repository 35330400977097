import {ApiBase} from '@jetCommon/api/base';

export default class PersonApiCommon extends ApiBase {
    static resourceName = 'persons';

    self() {
        return this.retrieve('self');
    }

    checkSpaAccess(url) {
        return this.apiGet('self/check_spa_access/', {
            params: {url},
        });
    }

    patchWithNotification(personId, data) {
        return this.apiPatch(`${personId}/patch_and_notify/`, data);
    }

    updatePersonalEmail(personId, data) {
        return this.apiPost(`${personId}/update_personal_email/`, data);
    }
}
